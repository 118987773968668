h1 {
  color: var(--color-gs-1);
  font-size: 1.5rem;
  font-weight: 300;
}
h2 {
  max-width: 680px;
  margin: var(--gap-3) 0;
}
strong {
  font-size: 1em;
  font-weight: 600;
  padding-left: var(--gap-1);
  padding-right: var(--gap-1);
  //background-color: var(--color-b-8);
  animation: highlightText 3s;
  background:linear-gradient(to right, #000, #000) no-repeat 50px 50px;
}
.error {
  color: var(--chart-color-contrast-5);
}
.success {
  color: var(--chart-color-contrast-1);
}
.pill {
  border: 1px solid var(--color-gs-5);
  padding: var(--gap-1) var(--gap-3);
  border-radius: 3vh;
  width: fit-content;
}

mark {
  font-size: 1em;
  padding-left: var(--gap-1);
  padding-right: var(--gap-1);
  -webkit-animation: .5s highlight .5s 1 ease-out forwards;
  animation: .5s highlight .5s 1 ease-out forwards;
  background: linear-gradient(90deg, var(--color-b-9) 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 100%;
  background-position: 100% 0;
  border-bottom-right-radius: var(--gap-3);
  border-top-left-radius: var(--gap-3);
}


.frame {
  border-radius: var(--gap-2);
  color: var(--color-gs-2);
  display: block;
  opacity: 0.9;
  padding: var(--gap-2);
  width: 100%;
  max-width:680px;
  background-color: white;
  font-size: var(--font-size-3);
  margin: var(--gap-3) auto;
  border: 1px solid var(--color-gs-6);
}
.frame.hoverable {
  cursor: pointer;}
.frame.hoverable:hover {
  background-color: var(--color-gs-8);
}

.uppercase {
  text-transform: uppercase;}

.spinner {
  background-color: var(--color-c-5);
  position: fixed;
  z-index : 99999;
  display: block;
  height: 100%;
  width: 100%;

}
@-webkit-keyframes highlight {
  to {
    background-position: 0 0;
  }
}

@keyframes highlight {
  to {
    background-position: 0 0;
  }
}

/* HTML: <div class="loader"></div> */

.loader {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid var(--color-c-2);
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid var(--color-c-2);
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid var(--color-c-2);
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
