@keyframes animateleft {
  from {
    left: -300px;
    opacity: 0
  }

  to {
    left: 0;
    opacity: 1
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0)
  }

  to {
    transform: scale(1)
  }
}

@keyframes opac {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes highlightText {
  from {
    background-position-x: -200;
  }

  to {
    background-position-x: -10;
  }
}

@keyframes reveal {
  80%{
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
  }
}
@keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
}
