/* You can add global styles to this file, and also import other style files */
/*@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/girona-test');
@import url('https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@400..700&display=swap');/* Add application styles & imports to this file! */
@import 'vanilla-cookieconsent/dist/cookieconsent.css';
@import "assets/scss/reset";
@import "assets/scss/form-items";
@import "assets/scss/animations";
@import "assets/scss/common";
html {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
  font-family: var(--font-body);
  font-size: var(--font-size-3);
  margin: 0;
  padding: 0;
}
body {
  background-color: white;
}

:root {
  /*--font-header:'Cormorant Garamond', serif;
  --font-body:'Cormorant Garamond', serif;*/
  --font-header: 'Geologica', sans-serif;
  --font-design : 'Girona Test', sans-serif;
  --font-body: 'Karla', sans-serif;
  --font-footer-company: 'Geologica', sans-serif;

  --breakpoint-large: 993px;
  --footer-height : 390px;

  --header-height : 52px;

  --left-sidebar-width:240px;

  --font-size-1:6pt;
  --font-size-2:9pt;
  --font-size-3:12pt;
  --font-size-4:14pt;
  --font-size-5:20pt;
  --font-size-6:30pt;
  --font-size-7:48pt;
  --font-size-8:80pt;
  --font-size-9:100pt;


  --gap-1:2pt;
  --gap-2:4pt;
  --gap-3:8pt;
  --gap-4:12pt;
  --gap-5:28pt;
  --gap-6:42pt;
  --gap-7:82pt;
  --gap-8:90pt;
  --gap-9:64pt;

  --color-a-0: #2B2B02;
  --color-a-1: #555504;
  --color-a-2: #808007;
  --color-a-3: #AAAA09;
  --color-a-4: #D5D50B;
  --color-a-5: #FFFF0D;
  --color-a-6: #FFFF35;
  --color-a-7: #FFFF5E;
  --color-a-8: #FFFF86;
  --color-a-9: #FFFFAE;
  --color-a-10: #FFFFD7;

  --color-a-0-rgba: rgba(43, 43, 2, 0.9);
  --color-a-1-rgba: rgba(85, 85, 4, 0.9);
  --color-a-2-rgba: rgba(128, 128, 7, 0.9);
  --color-a-3-rgba: rgba(170, 170, 9, 0.9);
  --color-a-4-rgba: rgba(213, 213, 11, 0.9);
  --color-a-5-rgba: rgba(255, 255, 13, 0.9);
  --color-a-6-rgba: rgba(255, 255, 53, 0.9);
  --color-a-7-rgba: rgba(255, 255, 94, 0.9);
  --color-a-8-rgba: rgba(255, 255, 134, 0.9);
  --color-a-9-rgba: rgba(255, 255, 174, 0.9);
  --color-a-10-rgba: rgba(255, 255, 215, 0.9);


  --color-b-0: #000000;
  --color-b-1: #1a1400;
  --color-b-2: #332800;
  --color-b-3: #4d3c00;
  --color-b-4: #664f00;
  --color-b-5: #806300;
  --color-b-6: #997700;
  --color-b-7: #b28b00;
  --color-b-8: #cc9e00;
  --color-b-9: #e6b200;
  --color-b-10: #ffd600;
  --color-b-11: #fff2cc;
  --color-b-12: #ffffff;

  --color-b-0-rgba: rgba(0, 0, 0, 0.9);
  --color-b-1-rgba: rgba(26, 20, 0, 0.9);
  --color-b-2-rgba: rgba(51, 40, 0, 0.9);
  --color-b-3-rgba: rgba(77, 60, 0, 0.9);
  --color-b-4-rgba: rgba(102, 79, 0, 0.9);
  --color-b-5-rgba: rgba(128, 99, 0, 0.9);
  --color-b-6-rgba: rgba(153, 119, 0, 0.9);
  --color-b-7-rgba: rgba(178, 139, 0, 0.9);
  --color-b-8-rgba: rgba(204, 158, 0, 0.9);
  --color-b-9-rgba: rgba(230, 178, 0, 0.9);
  --color-b-10-rgba: rgba(255, 214, 0, 0.9);
  --color-b-11-rgba: rgba(255, 242, 204, 0.9);
  --color-b-12-rgba: rgba(255, 255, 255, 0.9);


  /*
    --color-b-0: #280C27;
    --color-b-1: #51194D;
    --color-b-2: #792574;
    --color-b-3: #A1329A;
    --color-b-4: #CA3EC1;
    --color-b-5: #F24BE7;
    --color-b-6: #F469EB;
    --color-b-7: #F687EF;
    --color-b-8: #F9A5F3;
    --color-b-9: #FBC3F7;
    --color-b-10: #FDE1FB;

    --color-b-0-rgba: rgba(40, 12, 39, 0.9);
    --color-b-1-rgba: rgba(81, 25, 77, 0.9);
    --color-b-2-rgba: rgba(121, 37, 116, 0.9);
    --color-b-3-rgba: rgba(161, 50, 154, 0.9);
    --color-b-4-rgba: rgba(202, 62, 193, 0.9);
    --color-b-5-rgba: rgba(242, 75, 231, 0.9);
    --color-b-6-rgba: rgba(244, 105, 235, 0.9);
    --color-b-7-rgba: rgba(246, 135, 239, 0.9);
    --color-b-8-rgba: rgba(249, 165, 243, 0.9);
    --color-b-9-rgba: rgba(251, 195, 247, 0.9);
    --color-b-10-rgba: rgba(253, 225, 251, 0.9);
  */

  --color-c-0: #0C281C;
  --color-c-1: #195139;
  --color-c-2: #257955;
  --color-c-3: #32A171;
  --color-c-4: #3ECA8D;
  --color-c-5: #4BF2AA;
  --color-c-6: #69F4B8;
  --color-c-7: #87F6C6;
  --color-c-8: #A5F9D4;
  --color-c-9: #C3FBE3;
  --color-c-10: #E1FDF1;

  --color-c-0-rgba: rgba(12, 40, 28, 0.9);
  --color-c-1-rgba: rgba(25, 81, 57, 0.9);
  --color-c-2-rgba: rgba(37, 121, 85, 0.9);
  --color-c-3-rgba: rgba(50, 161, 113, 0.9);
  --color-c-4-rgba: rgba(62, 202, 141, 0.9);
  --color-c-5-rgba: rgba(75, 242, 170, 0.9);
  --color-c-6-rgba: rgba(105, 244, 184, 0.9);
  --color-c-7-rgba: rgba(135, 246, 198, 0.9);
  --color-c-8-rgba: rgba(165, 249, 212, 0.9);
  --color-c-9-rgba: rgba(195, 251, 227, 0.9);
  --color-c-10-rgba: rgba(225, 253, 241, 0.9);


  --color-d-0: #19280C;
  --color-d-1: #315119;
  --color-d-2: #4A7925;
  --color-d-3: #62A132;
  --color-d-4: #7BCA3E;
  --color-d-5: #94F24B;
  --color-d-6: #A5F469;
  --color-d-7: #B7F687;
  --color-d-8: #C9F9A5;
  --color-d-9: #DBFBC3;
  --color-d-10: #EDFDE1;

  --color-d-0-rgba: rgba(25, 40, 12, 0.9);
  --color-d-1-rgba: rgba(49, 81, 25, 0.9);
  --color-d-2-rgba: rgba(74, 121, 37, 0.9);
  --color-d-3-rgba: rgba(98, 161, 50, 0.9);
  --color-d-4-rgba: rgba(123, 202, 62, 0.9);
  --color-d-5-rgba: rgba(148, 242, 75, 0.9);
  --color-d-6-rgba: rgba(165, 244, 105, 0.9);
  --color-d-7-rgba: rgba(183, 246, 135, 0.9);
  --color-d-8-rgba: rgba(201, 249, 165, 0.9);
  --color-d-9-rgba: rgba(219, 251, 195, 0.9);
  --color-d-10-rgba: rgba(237, 253, 225, 0.9);


  --color-e-1: #8ac5a5;
  --color-e-2: #8fcfb2;
  --color-e-3: #95d9bf;
  --color-e-4: #9ae3cc;
  --color-e-5: #a0edda;
  --color-e-6: #a5f7e7;
  --color-e-7: #aafbf4;
  --color-e-8: #affff1;
  --color-e-9: #b4fef9;

  --color-e-1-rgba: rgba(138, 197, 165, 0.9);
  --color-e-2-rgba: rgba(143, 207, 178, 0.9);
  --color-e-3-rgba: rgba(149, 217, 191, 0.9);
  --color-e-4-rgba: rgba(154, 227, 204, 0.9);
  --color-e-5-rgba: rgba(160, 237, 218, 0.9);
  --color-e-6-rgba: rgba(165, 247, 231, 0.9);
  --color-e-7-rgba: rgba(170, 251, 244, 0.9);
  --color-e-8-rgba: rgba(175, 255, 241, 0.9);
  --color-e-9-rgba: rgba(180, 254, 249, 0.9);

  --color-gs-1: #111;
  --color-gs-2: #333;
  --color-gs-3: #444;
  --color-gs-4: #555;
  --color-gs-5: #999;
  --color-gs-6: #CCC;
  --color-gs-8: #EEE;
  --color-gs-9: #FAFAFA;

  --main-color-c-5:#25273A;
  --main-color-c-9:#6A769E;

  --chart-color-1:#C6E0BF;
  --chart-color-2:#7BBEE8;
  --chart-color-3:#F2EDAF;
  --chart-color-4:#F2CF92;
  --chart-color-5:#E87D75;

  --chart-color-light-1: #F0FFF0; /* Vert très pastel */
  --chart-color-light-2: #CFECEC; /* Bleu très pastel */
  --chart-color-light-3: #FAFAD2; /* Jaune très pastel */
  --chart-color-light-4: #FAEBD7; /* Orange très pastel */
  --chart-color-light-5: #FFB6C1; /* Rouge très pastel */


  --chart-color-contrast-1:#63705f;
  --chart-color-contrast-2:#3d5f74;
  --chart-color-contrast-3:#797657;
  --chart-color-contrast-4:#917c57;
  --chart-color-contrast-5:#743e3a;

}
