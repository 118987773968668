a {cursor: pointer}
a:hover {
  opacity: 0.7 !important;
  transition: opacity 0.2s ease-in-out;
}
label {
  font-weight: 300;
}
/* trick to custom input colors */
input[type="text"]:-webkit-autofill,
input[type="text"]:-webkit-autofill:hover,
input[type="text"]:-webkit-autofill:focus,
input[type="password"]:-webkit-autofill,
input[type="password"]:-webkit-autofill:hover,
input[type="password"]:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-d-1); //text color
  -webkit-box-shadow: 0 0 0px 40rem var(--color-d-8) inset; // background color
}
input[type="text"],
input[type="text"]:focus,
input[type="password"],
input[type="password"]:focus,
{
  background-color: rgba(255,255,255,.5);
  border-radius: var(--gap-1);
  border: 1px solid var(--color-c-2);
  color: black;
  font-size: var(--font-size-3);
  font-weight: normal;
  margin-bottom: var(--gap-3);
  padding: var(--gap-2);
  width: 100%;
}
input:focus, textarea {
  outline: none !important;
}

input[type="color"] {
  border-radius: var(--gap-3);
  cursor: pointer;
  display: block;
  margin: 0 auto;
  width: var(--gap-5);
  height: var(--gap-5);
}
/*
button {
  border: 1px solid var(--color-d-1);
  border-radius: var(--gap-2);
  background-color: var(--color-d-1);
  color: var(--color-d-8);
  cursor:pointer;
  padding: var(--gap-2) var(--gap-3);
  text-transform: uppercase;
}
button:hover {
  background-color: var(--color-d-8);
  color: var(--color-d-1);
}*/

button {
  cursor:pointer;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-decoration: none;
  color: var(--color-d-1);
  font-size: var(--font-size-5);
  border-radius: 0;
  position: relative;
  transition: 0.3s;
  background-color: rgba(255,255,255,.1);
  border-style: none;
}
button[type="submit"]:disabled {
  opacity:.3;
  cursor: default;
}
.toast-container button {
  width: auto;
  height: auto;
}
.toast-container button::before, .toast-container button::after {
  border: none;
}

.btn-primary {
  background-color: var(--color-c-3);
  border-radius: var(--gap-3);
  cursor: pointer;
  color: white;
  font-weight: 300;
  font-family: var(--font-body);
  font-size: 1em;
  padding: var(--gap-2) var(--gap-3);
  margin: 0 auto;
  display: block;
  text-align: center;
}
.btn-primary:hover {
  background-color: var(--color-c-4);
}

button.btn-secondary {
  border-radius: var(--gap-3);
  cursor: pointer;
  font-weight: 300;
  font-family: var(--font-body);
  font-size: 1em;
  padding: var(--gap-2) var(--gap-3);
  margin: 0 auto;
  display: block;
  text-align: center;
  background-color: var(--color-d-8);
  color: var(--color-d-1);
}
button.btn-secondary:hover {
  background-color: var(--color-d-1);
  color: var(--color-d-8);
}

button.btn-tertiary {
  background-color: var(--color-a-2);
  border: none;
  color: white;
}
button.btn-tertiary:hover {
  background-color: var(--color-a-3);
}

button.btn-clear{
  background-color: transparent;
  border-color: transparent;
  color: white;
  text-align: center;
  text-decoration: underline;
  text-transform: lowercase;
}

button.btn-clear:hover {
  color: var(--color-gs-6);
}

button.btn-large {
  font-size: 1.2em;
  padding: var(--gap-3);
}
.pwd-check ul  {
  background-color: var(--color-gs-9);
  border-radius: var(--gap-1);
  padding: var(--gap-3);
  margin-top: var(--gap-3);
  list-style-type: none;
  li {
    padding: var(--gap-1);
  }
  li.success:before {
    position: relative;
    top: 3px;
    margin-right: 2px;
    content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMzIgMzIiPjxwYXRoIGZpbGw9IiNjNmUwYmYiIGQ9Ik0xNiAyYTE0IDE0IDAgMSAwIDE0IDE0QTE0IDE0IDAgMCAwIDE2IDJtLTIgMTkuNTlsLTUtNUwxMC41OSAxNUwxNCAxOC40MUwyMS40MSAxMWwxLjU5NiAxLjU4NloiLz48cGF0aCBmaWxsPSJub25lIiBkPSJtMTQgMjEuNTkxbC01LTVMMTAuNTkxIDE1TDE0IDE4LjQwOUwyMS40MSAxMWwxLjU5NSAxLjU4NXoiLz48L3N2Zz4=");
  }
  li.error:before {
    position: relative;
    top: 3px;
    margin-right: 2px;
    content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMzIgMzIiPjxwYXRoIGZpbGw9IiNlODdkNzUiIGQ9Ik0xNiAyQzguMyAyIDIgOC4zIDIgMTZzNi4zIDE0IDE0IDE0czE0LTYuMyAxNC0xNFMyMy43IDIgMTYgMm0tMS4xIDZoMi4ydjExaC0yLjJ6TTE2IDI1Yy0uOCAwLTEuNS0uNy0xLjUtMS41UzE1LjIgMjIgMTYgMjJzMS41LjcgMS41IDEuNVMxNi44IDI1IDE2IDI1Ii8+PC9zdmc+");
  }
}


input[type="number"],
input[type="number"]:focus {
  background-color: rgba(255,255,255,.5);
  border-radius: var(--gap-1);
  border: 1px solid var(--color-c-2);
  color: black;
  font-size: var(--font-size-3);
  font-weight: normal;
  padding: var(--gap-1);
}

.checkbox {
  display: inline-block;
  margin-left: var(--gap-3);

  input[type="checkbox"] {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    cursor: pointer;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;
    transition: all 100ms ease-out;
  }

  input[type="checkbox"]::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
  }
  input[type="checkbox"]::after {
    transition: all 100ms ease-out;
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 14px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 14px;
  }
  input[type="checkbox"]:hover {
    background-color: #c9cbcd;
    transition-duration: 0s;
  }
  input[type="checkbox"]:checked {
    background-color: var(--color-c-5);
  }
  input[type="checkbox"]:checked::after {
    background-color: #fff;
    left: 13px;
  }
  input[type="checkbox"]:checked:hover {
    background-color: var(--color-c-4);
  }
}
.checkbox :focus:not(.focus-visible) {
  outline: 0;
}
